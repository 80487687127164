import React from 'react';
import { useStaticQuery, graphql} from "gatsby";
import Layout from "../components/Layout";
import SiteHelmet from "../components/SiteHelmet";
import DataTable from "../components/DataTable";

const Gigs = () => {
  const data = useStaticQuery(
    graphql`
        query {
            allGigsJson {
                edges {
                    node {
                        band
                        date
                        venue
                        support
                    }
                }
            }
        }
    `,
  );

  const gigs = data.allGigsJson.edges.map(gig => {
    return gig.node
  });

  const tableColumns = [
    { title: 'Artist', field: 'band' },
    { title: 'Date', field: 'date' },
    { title: 'Venue', field: 'venue' },
    { title: 'Support', field: 'support' }
  ];

  return (
    <Layout>
      <SiteHelmet title="Gigs"/>
      <DataTable
        columns={tableColumns}
        data={gigs}
        title={"Gigs I've been to"}
      />
    </Layout>
  );
};

export default Gigs;